html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-smooth: always;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  scroll-behavior: smooth;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

@import url('//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');

.accordion::after {
  transform: all .3s;
  content: "\f107";
  color: #ffe300;
  right: -10px;
  position: relative;
  font-family: "FontAwesome"
}

.active::after {
  content: "\f106" !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(font.woff) format("woff");
}

body {
  margin: 0;
  font-family: "Open Sans", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body,
section.horizontal {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

section {
  width: 100%;
  height: 100%;
  position: relative;
}


section:nth-of-type(odd) {
  background-color: #000;
}

section:nth-of-type(even) {
  background-color: #000;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
}



.button-49,
.button-49:after {
  cursor: pointer;
  font-weight: 800;
  width: 250px;
  height: 76px;
  line-height: 78px;
  font-size: 15px;
  font-family: Orbitron, sans-serif;
  background: linear-gradient(45deg, transparent 5%, #2D373E 5%);
  border: 0;
  color: #cacaca;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #FFE300;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transform: scale(.7);
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  transform: scale(.7);
  content: 'BenNFT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #FFE300 3%, #FFE300 5%, #FFE300 5%);
  text-shadow: -3px -3px 0px #000, 3px 3px 0px #727272;
  clip-path: var(--slice-0);
}

.button-49:hover {
  transform: scale(.8);
}




.spinner {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
}

.removeSpinner {
  display: none !important;
}

.sk-cube-grid {
  width: 240px;
  height: 240px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #ffe300ad;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
    border: solid 1px #000;
  }

  35% {
    border: solid 11px #000;
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.blank {
  background: transparent;
}

.ml14 {
  font-weight: 700;
  font-size: 30px;
}

.ml14 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml14 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #cacaca;
  transform-origin: 100% 100%;
  bottom: 0;
}

.ml14 .letter {
  display: inline-block;
  opacity: 0;
  line-height: 1em;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  border-radius: 15px;
  margin: 40px 0px 0px 0px;
  background: #2D373E;
  color: #cacaca;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: center;
  font-family: Orbitron;
  font-size: 1.6rem;
  border: solid 0px #14181b;
  outline: none;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: hsl(205, 6%, 19%);
}

.active {}

#accordionContainer {
  width: 60vw;
  max-width: 800px;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  border-radius: 0px 0px 10px 10px;
  margin-top: -25px;
  padding: 10px 18px;
  background-color: #E8E9EA;
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.panel p {
  font-family: Open Sans;
  padding: 40px 40px;
  line-height: 1.1;
}


.social-link1:hover,
.social-link2:hover,
.social-link3:hover {
  transform: scale(1.2);
}

.site-link1 a:hover {
  color: #FFE300;
}

.current {
  color: #FFE300;
}

.item1 {
  width: 100vw;
  height: 100vh;
  background: url("./homeAlt.png") no-repeat center center;
  transition: all 1.8s;
  background-size: cover !important;
  padding: 160px 140px;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  border: solid 2px #000;
  z-index: 20;
  box-shadow: inset -19px -2px 63px 20px #000, 0px 0px 15px 13px #000;
}

button {
  transition: all .8s;
}

button:hover {
  transition: all .8s;
}

#item2 {
  width: 100vw;
  height: 110vh;
  background: url("./whatis.png") no-repeat right bottom;
  animation: all 11.8s;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 13;
  box-shadow: 2px 4px 23px 2px #d0b60a;
}

#item2::before {
  background: url("./conorBenn.png") no-repeat center bottom;
}

#item3 {
  padding: 80px 55px !important;
  width: 100vw;
  height: auto;
  min-height: 110vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: url("./utility.png") no-repeat left bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

#item4 {
  min-height: 130vh;
  padding: 210px 125px;
  box-shadow: inset 19px -2px 63px 20px #000, 0px -20px 60px 19px #000;
  background: url("./mint.png") center center no-repeat;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  mask-image: linear-gradient(10deg, transparent 2%, black 25%, black 92%, transparent 100%), linear-gradient(to top, transparent 5%, transparent 92%);
}

#faq {
  padding: 100px 125px;
  box-shadow: 0px 10px 22px -9px #cacaca;
  height: 130vh !important;
  z-index: 10;
  min-height: 140vh;
  background: url("./faq.png") center bottom no-repeat;
  background-size: cover !important;
}

#timelineContainer {
  height: auto;
  background: url("./RoadMap.png") center right no-repeat;
  background-size: cover !important;
}

#mlb2-1354623.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
  border: solid 2px #d7c41380;
  background: #ffffff8a;
  max-width: 400px;
  width: 100%;
}


#mlb2-1354623.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input {
  background-color: #ffffff66 !important;
}

.g-recaptcha {
  opacity: 0.8;
  filter: hue-rotate(188deg);
}


.site-footer {
  background: linear-gradient(to bottom, #020606, #000 100%) !important;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5
}

.site-footer hr.small {
  margin: 20px 0
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
}

.footer-links a {
  transition: all .4s;
  color: #606a72;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #ffe300;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}

.site-footer .social-icons {
  text-align: right;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #CC0000
}

.copyright-text {
  margin: 0
}

@media (max-width:1450px) {
  .heroHeader {
    font-size: 36px !important;
  }
}

@media (max-width:991px) {
  .site-footer [class^=col-] {
    margin-bottom: 30px
  }
}

@media (max-width:767px) {
  .site-footer {
    padding-bottom: 0
  }

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.twitter:hover {
  background-color: #00aced
}

.social-icons a.linkedin:hover {
  background-color: #007bb6
}

.social-icons a.dribbble:hover {
  background-color: #ea4c89
}

@media (max-width:767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}

#buyButton1 {
  color: #cacaca !important;
  margin: 15px;
  background: #2D373E;
  box-shadow: none;
  width: 225px;
  height: 52px;
  padding: 10px 5px;
  font-size: 13px;
  font-weight: 800;
  font-family: Orbitron;
  box-shadow: 0px 1px 5px 3px #ffe300ad;
}
#buyButton2 span {
  color: #cacaca !important;
}
#buyButton2 {
  color: #cacaca !important;
  margin: 15px;
  background: #2D373E;
  box-shadow: none;
  width: 225px;
  height: 52px;
  padding: 10px 5px;
  font-size: 13px;
  font-weight: 800;
  font-family: Orbitron;
  box-shadow: 0px 1px 5px 3px #ffe300ad;
}

.crossmintButton-0-2-1:hover:enabled {
  cursor: pointer;
  opacity: 1 !important;
}

#buyButton1:hover,
#buyButton2:hover {
  transform: scale(1.1);
}

.supplyContainer {
  display: flex;
  font-size: 1.4vw;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.supplyContainer .supplyLeft {
  border-radius: 15px 0px 0px 15px;
  background: #CACACA;
  color: #000;
  width: 16vw;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.supplyContainer .supplyRight {
  border-radius: 0px 15px 15px 0px;
  background: #2D373E;
  color: #cacaca;
  width: 16vw;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.span1 {
  font-size: 20px;
  text-align: center;
  border-radius: 15px;
  padding: 12px 6px;
  margin-bottom: 15px;
  border: solid #cacaca 2px;
  background: transparent;
}

.span2 {
  font-size: 25px;
  text-align: center !important;
}

.quantityContainer {
  display: flex;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.4vw;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.quantityContainer .quantityLeft {
  border-radius: 15px 0px 0px 15px;
  background: #cacaca;
  color: #000;
  width: 16vw;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.quantityContainer .quantityRight {
  border-radius: 0px 15px 15px 0px;
  background: #2D373E;
  color: #cacaca;
  width: 16vw;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}


.spanContainer1,
.spanContainer2,
.spanContainer3 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  width: 33.33%;
  margin: 15px;
}

#countdown div {
  display: flex;
  flex-direction: row;
}


.countdownContainer {
  z-index: 200;
  margin-top: 120px;
}


.cardgrid_container {
  max-width: 1200px;
  margin: 0 auto;
}

.cardgrid_container h1 {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: .2rem;
  text-align: center;
}

.cardgrid_container img {
  filter: grayscale(1);
  height: auto;
  max-height: 100px;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  color: #cacaca;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

}

.cards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 25%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 25%;
  }
}

.card {
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50px;
  /* border: solid 2px #2e2b2b; */
  border-radius: 50px;
  background: linear-gradient(to bottom, #2D373E 65%, #3C4952 66%);
}

.card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 170px;
  background: #3C4952;
  padding: 1rem;
}

.card_image {
  display: flex;
  justify-content: center;
  padding: 12.5px;
}


.card_title {
  color: #ffffffed;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  font-family: 'Orbitron';
  letter-spacing: .2rem;
  text-align: center;
}

.card_text {
  color: #A3ABB1;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1.25rem;
  font-weight: 400;
  font-family: Open Sans;
  text-align: center;
}

.made_by {
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}

/* TIMELINE Start */


.timeline {
  position: relative;
  width: 660px;
  margin-right: auto;
  margin-left: 13vw;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80, 80, 80);
  background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 87, 153, 1)), color-stop(100%, rgba(125, 185, 232, 1)));
  background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);

  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;

  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: #ffe300 !important;
  padding: 6px 10px;
  border-radius: 5px;
  letter-spacing: .2rem;
  font-family: Orbitron;
  font-weight: 900;
  text-align: left;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -40px;
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: #cacaca;
  border-radius: 10px;
  border: 4px solid #b5a418 !important;
  z-index: 10;
}

.direction-r .flag:before {
  left: -40px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: #ffe300;
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: #ffe300;
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;

  line-height: 1em;
  font-weight: 700;
  font-size: 0.8em;
  color: #000000ed;
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  font-family: Orbitron;
  display: inline-block;
  padding: 4px 6px;
  letter-spacing: .2rem;
  font-weight: 9 00;
  background: rgb(248, 248, 248);
}

.desc {
  background: #ffffff59;
  padding: 10px 20px;
  color: #ffffffed;
  border-radius: 15px;
  margin: 20px 10px;
  font-weight: medium;
  font-family: 'Open Sans';
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {

  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    background: rgb(255, 255, 255);
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 50%;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid #b5a418;
    z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245, 245, 245);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);

    z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;

    z-index: 15;
  }

}

@media screen and (min-width: 400px ?? max-width: 660px) {

  .direction-l .desc,
  .direction-r .desc {
    margin: 1em 4em 0 4em;
  }

}


/* TIMELINE END */

.heroHeader {
  font-size: 30px;
  color: white;
  font-weight: 900;
  font-family: Orbitron;
  letter-spacing: .2rem;
  opacity: 0;
}

#mintHeroBtn {
  transition: all .3s;
}

#mintHeroBtn:hover {
  border: solid 1px #ddd !important;
}

.heroHeader2 {
  font-size: 40px;
  color: white;
  font-family: Orbitron;
  opacity: 1;
  text-align: left;
  letter-spacing: .2rem;
  width: 60vw;
}


.heroSubText {
  margin-top: 25px;
  color: white;
  font-family: "Open Sans";
  font-size: 28px;
  opacity: 0;
}

.heroSubText2 {
  padding-right: 30vw;
  margin: 20px 50px;
  color: white;
  font-family: Orbitron;
  letter-spacing: .2rem;
  font-weight: 900;
  font-size: 13px;
  opacity: 1;
  width: 60vw;

}

.upScroll {
  animation: all .4s;
  top: -500px !important;
  opacity: 0 !important;
}

.downScroll {
  animation: all .4s;
  top: 21px !important;
  opacity: 1 !important;
}

.logocontainer {
  font-family: Orbitron;
  font-size: 27px;
}

.site-links {
  font-size: 23px;
  font-family: Orbitron;
}

.social-mobile {
  display: block !important;
  z-index: 6000 !important;
  position: relative !important;
}

.site-links,
.socials {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.site-link1 {
  transition: all .3s;
  padding: 10px;
  text-decoration: none;
}

.site-link1 a {
  transition: all .3s;
  text-decoration: none;
}

.social-link1 a,
.social-link1 {
  transition: all .3s;
  margin: 10px;
  width: 28px;
  height: 28px;
  background: url("./insta.png") no-repeat center center;
  background-size: cover;
}

.social-link2 a,
.social-link2 {
  transition: all .3s;
  margin: 10px;
  width: 28px;
  height: 28px;
  background: url("./twitterColor.png") no-repeat center center;
  background-size: cover;
}

.social-link3 a,
.social-link3 {
  transition: all .3s;
  margin: 10px;
  width: 28px;
  height: 28px;
  background: url("./discordWhite.webp") no-repeat center center;
  background-size: cover;
}



.navContainer {
  width: 100vw;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
}

.nav-header-hide {
  animation: opacity 1.2s;
  height: 100px;
  width: 96%;
  opacity: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: rgb(0, 0, 0, .8);
  border-radius: 20px;
  color: white;
  align-items: center;
  position: absolute;
  z-index: 99;
  overflow: hidden;
  top: 21px;
}

.nav-header-show {

  opacity: 1;
  height: 120px;
  width: 90vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: #00000070;
  border-radius: 20px;
  color: white;
  align-items: center;

}

section.horizontal {
  overflow-x: hidden;
}

section.horizontal .pin-wrap,
section.horizontal .animation-wrap {
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
}


section.horizontal .item {
  position: relative;
  padding: 120px 140px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  line-height: 1.7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  background-color: transparent;
}

section.horizontal .item:before {
  position: absolute;
  font-size: 100px;
  opacity: .13;
  font-weight: bold;
  z-index: -1;
  -webkit-transform: translate(-30px, -50px);
  transform: translate(-30px, -50px);
  line-height: 1;
}




section.horizontal .animation-wrap.to-right {
  counter-reset: item;
  float: left;
}

section.horizontal .animation-wrap.to-left {
  counter-reset: item 11;
  float: right;
}

section.horizontal .animation-wrap.to-right .item:before {
  counter-increment: item;
  content: counter(item);
}

section.horizontal .animation-wrap.to-left .item:before {
  counter-increment: item -1;
  content: counter(item);
}



section.horizontal .animation-wrap .item:nth-child(2n+2) {
  align-items: flex-start;
}

section.horizontal .animation-wrap .item:nth-child(4n+4) {
  align-items: flex-end;
}

video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}

ol.gradient-list>li::before,
ol.gradient-list>li {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}


button,
a {
  cursor: pointer !important;
}


.open {
  position: fixed;
  top: 90vh;
  right: 40px;
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.cls {
  z-index: 1000;
  background-color: #fff !important;
}

.open span {
  display: block;
  float: left;
  clear: both;
  height: 4px;
  width: 40px;
  border-radius: 40px;
  background-color: #ffe300;
  position: absolute;
  right: 3px;
  top: 3px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.open span:nth-child(1) {
  margin-top: 10px;
  z-index: 9;
}

.open span:nth-child(2) {
  margin-top: 25px;
}

.open span:nth-child(3) {
  margin-top: 40px;
}

.sub-menu {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  height: 0;
  width: 0;
  right: 0;
  bottom: 10px;
  position: absolute;
  background-color: rgba(255, 225, 0, 0.78);
  border-radius: 50%;
  z-index: 18;
  overflow: hidden;
}

.sub-menu li {
  display: block;
  float: right;
  clear: both;
  height: auto;
  margin-right: -160px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sub-menu li:first-child {
  margin-top: 45px;
}

.sub-menu li:nth-child(1) {
  -webkit-transition-delay: 0.05s;
}

.sub-menu li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
}

.sub-menu li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
}

.sub-menu li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
}

.sub-menu li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
}

.sub-menu li a {
  text-decoration: none;
  color: #2D373E;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  font-family: Open Sans;
  display: block;
  float: left;
  line-height: 40px;
}

.oppenned .sub-menu {
  z-index: 100;
  opacity: 1;
  height: 55vh;
  width: 400px;
}

.oppenned span:nth-child(2) {
  overflow: visible;
}

.oppenned span:nth-child(1),
.oppenned span:nth-child(3) {
  z-index: 100;
  transform: rotate(45deg);
}

.oppenned span:nth-child(1) {
  transform: rotate(45deg) translateY(12px) translateX(12px);
}

.oppenned span:nth-child(2) {
  height: 40vh;
  width: 400px;
  right: -160px;
  top: -160px;
  border-radius: 50%;
  background-color: transparent;
}

.oppenned span:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px) translateX(10px);
}

.oppenned li {
  margin-right: 168px;
}

a {
  text-decoration: none !important;
}

.button {
  display: block;
  float: left;
  clear: both;
  padding: 20px 40px;
  background: #cacaca;
  border-radius: 3px;
  border: 2px solid #10a1ea;
  overflow: hidden;
  position: relative;
}

.button:after {
  transition: transform 0.3s ease;
  content: "";
  position: absolute;
  height: 200px;
  width: 400px;
  transform: rotate(45deg) translateX(-540px) translateY(-100px);
  background: #10a1ea;
  z-index: 1;
}

.button:before {
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: attr(title);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #cacaca;
  z-index: 2;
  text-align: center;
  padding: 20px 40px;
  transform: translateY(200px);
}

.button:hover {
  text-decoration: none;
}

.button:hover:after {
  transform: translateX(-300px) translateY(-100px);
}

.button:hover:before {
  transform: translateY(0);
}



main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 5rem;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
}

ol.gradient-list>li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

ol.gradient-list>li::before,
ol.gradient-list>li::after {
  background: linear-gradient(135deg, #000 0%, #CC0000 100%);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

ol.gradient-list>li::before {
  font-family: Orbitron;
  align-items: center;
  content: counter(gradient-counter);
  color: #f5f5f5;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding: 0.125em 0.25em;
  z-index: 1;
}

ol.gradient-list>li:nth-child(1) {
  transform: translateX(-2rem);
}

ol.gradient-list>li:nth-child(2) {
  transform: translateX(2rem);
}

ol.gradient-list>li:nth-child(3) {
  transform: translateX(-2rem);
}

ol.gradient-list>li:nth-child(4) {
  transform: translateX(2rem);
}

ol.gradient-list>li:nth-child(5) {
  transform: translateX(-2rem);
}

ol.gradient-list>li:nth-child(6) {
  transform: translateX(2rem);
}

ol.gradient-list>li:nth-child(10n+1):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
  transform: translateX(-11);
}

ol.gradient-list>li:nth-child(10n+2):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+3):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+4):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+5):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+6):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+7):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+8):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+9):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li:nth-child(10n+10):before {
  background: linear-gradient(135deg, #CC000040 0%, rgba(0, 0, 0, 0.2) 100%);
}

ol.gradient-list>li+li {
  margin-top: 2rem;
}

#section2 {
  height: 100vh;
  backdrop-filter: grayscale(1);
}

#section2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./other-bg.png");
  filter: grayscale(100%);
}

.gradient-list2 {
  height: 500px;
}

.gradient-list2::before {
  content: "";
  bottom: 0;
  left: 0;
  background-position: center;
  transform: scale(1);
  position: absolute;
  width: 100%;
  height: 25%;
  z-index: 299;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./conorBenn.webp");
  filter: grayscale(100%);
}

.video-wrapper {
  width: auto;
  height: 100vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#textP,
text {
  font-family: Orbitron;
  fill: #CC0000 !important;
  font-weight: 800;
}

.custom-shape-divider-top-1660762286 {
  position: absolute;
  top: 100vh;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 10;
  font-size: 80px;
  color: red;
}

.custom-shape-divider-top-1660762286 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 394px;
}

.custom-shape-divider-top-1660762286 .shape-fill {
  fill: #000;
}

.custom-shape-divider-top-1660617241 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1660617241 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 122px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1660617241 .shape-fill {
  fill: #000000;
}

/** For mobile devices **/
@media (max-width: 767px) {

  .item1 {
    background-size: cover !important;
    padding: 200px 0 0 0px !important;
  }

  

  .blueStripe1 {
    display: none;
  }

  .heroHeader {
    margin: 40px 0px !important;
    width: 100% !important;
  }

  .heroSubText {
    font-size: 16px !important;
    margin-top: 15px !important;
    width: 70% !important;
  }

  .custom-shape-divider-top-1660617241 svg {
    width: calc(214% + 1.3px);
    height: 86px;
  }
}

.containerLogo {
  background: transparent;
  position: absolute;
  width: 100vw;
  height: auto;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  top: 5px;
}

.connectButton {
  background: transparent;
  position: absolute;
  width: 100vw;
  height: 38px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  top: 80vh;
}

.logo {
  padding: 0% 4%;
  color: white;
  position: relative;
  font-size: 60px;
  /* width: 120px;
  height: 38px; */
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.heroText {
  font-family: Rubik, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
  padding: 2.5%;
  margin: 10%;
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 10px 10px #000;
}




.button-85 {
  transition: all 1.3s;
  filter: grayscale(.7);
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:hover {

  filter: grayscale(0);
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #CC0000,
      #00058E,
      #CC0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

a {
  color: white;
}

@media (max-width: 840px) {

  #accordionContainer {
    width: 80vw !important;
  }

  #item3 {
    padding: 50px 15px !important;
  }

  .heroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #timelineContainer {
    height: auto;
    background: transparent;
    background-size: cover !important;
  }

  #faq {
    padding: 100px 125px;
    box-shadow: 0px 10px 22px -9px #cacaca;
    height: auto !important;
    z-index: 10;
    min-height: 110vh;
    background: url("./utilityMobile.png") center top no-repeat;
    background-size: cover !important;
  }

  .timeline {
    margin-left: 0 !important;
  }

  #faq {
    padding: 0 !important;
  }

  #item3 {
    background: url("./utilityMobile.png") no-repeat center bottom !important;
    background-size: cover !important;
  }

  #item4 {
    padding: 20px 40px !important;
  }

  .site-footer {
    padding: 20px !important;
  }

  #item2 {
    width: 100vw;
    height: 100vh;
    background: url("./whatisMobile.png") no-repeat right bottom;
  }

  .cards_item {
    width: 33.33%;
  }

  .logocontainer,
  .site-links,
  .socials {
    width: 33.33%
  }

  .navContainer,
  .nav-header-hide {
    padding: 0 40px;
  }

  .cards {
    display: flex;
    justify-content: center;
  }

  .supplyContainer,
  .quantityContainer {
    font-size: 30px;
  }

  .supplyContainer .supplyRight,
  .supplyContainer .supplyLeft {
    width: 30vw !important;
  }

  .quantityContainer .quantityLeft,
  .quantityContainer .quantityRight {
    width: 30vw !important;
  }

  #heroBtnContainer {
    right: 0 !important;
  }

  #mintHeroBtn {
    font-size: 25px !important;
  }

  .item1 {
    background: url("./home.png") no-repeat center center;
    padding: 150px 40px;
    justify-content: flex-start !important;
  }

  .heroHeader {
    margin-bottom: 10px !important;
  }

  #item2 {
    background-size: cover !important;
  }

  .site-links {
    display: none !important;
  }

  #heroBtnContainer {
    width: 70% !important;
  }

  .button-49,
  .button-49:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }

  .panel p {
    padding: 20px 0px;
  }

  #links {
    display: none !important;
  }

  .container {
    width: 100% !important;
  }
}


@media (max-width: 520px) {
  .containerMobile {
    display: none !important;
  }

  .panel p {
    padding: 20px 0 !important;
  }

  .cards_item {
    width: 100% !important;
  }

  .desc {
    background-color: #efefef !important;
    color: #000000ed !important;
    margin: 1em 5em 0 5em !important;
  }

  .heroSubText2 {
    line-height: 1.2 !important;
    padding-right: 2vw !important;
  }

  #item2 {
    height: 120vh !important;
    background-size: cover !important;
  }

  #item3 {
    height: auto !important;

  }

  .heroHeader2 h1 {
    margin-bottom: 3vh;
  }

  .logocontainer,
  .site-links,
  .socials {
    width: 50%
  }

  .logocontainer {
    padding-left: 20px;
  }

  .ml14 {
    font-weight: 200;
    letter-spacing: .2rem !important;
    font-size: 27px !important;
  }

  .button-49,
  .button-49:after {
    width: 140px !important;
    height: 86px;
    line-height: 88px;
  }

  .supplyContainer .supplyRight,
  .supplyContainer .supplyLeft {
    width: 39vw !important;
    font-size: 1.1rem;
  }

  .quantityContainer .quantityLeft,
  .quantityContainer .quantityRight {
    width: 39vw !important;
    font-size: 1.1rem;
  }

  #buyButton1,
  #buyButton2 {
    width: 160px !important;
    font-size: 13px !important;
  }

  .navContainer,
  .nav-header-hide {
    padding: 0 0px !important;
  }

  .nav-header-hide {
    justify-content: space-between !important;
  }

  .nav-header-hide {
    padding: 0 !important;
  }

  .heroHeader {
    margin-top: 15px !important;
  }

  .heroHeader h1 {
    font-size: 23px !important;
  }

  .item1 {
    padding: 120px 10px !important;
  }

  .heroSubText {
    width: 100% !important;
    font-size: 16px !important;
  }

  .socials {
    display: none !important;
  }
}

@media (max-width: 391px) {

  .nftImage1, .nftImage2 {
    margin: 12px !important;
    width: 45% !important;
  }
  #item3 {
    height: auto !important;

  }

  .heroSubText2{
    width: 75VW !important;
  }

  #item4 {
    padding: 20px 10px !important;
}

  .supplyContainer .supplyRight,
  .supplyContainer .supplyLeft {
    width: 39vw !important;
    font-size: 0.9rem;
  }

  .quantityContainer .quantityRight,
  .quantityContainer .quantityLeft {
    width: 39vw !important;
    font-size: 0.9rem;
  }

  .heroSubText {
    width: 100% !important;
    font-size: 13px !important;
  }

  .nav-header-hide {
    top: 0px !important;
  }

  .item1 {
    padding: 90px 10px !important;
  }

  #mintHeroBtn {
    width: 70%;
    font-size: 23px !important;
  }

  #heroSubText {
    width: 90% !important;
  }

  #item2 {
    height: 140vh !important;
    background-size: cover !important;
  }
}


@media (min-width: 1920px) {
  #heroBtnContainer {
    margin-top: 15px;
  }

  #mintHeroBtn {
    font-size: 20px !important;
  }

  .heroSubText {
    width: 70% !important;
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
}

@media (min-width: 920px) {
  .open {
    display: none !important;
  }
}


.fancy {
  margin: 50px 5px !important;
  line-height: 0.1;
  text-align: center;
}

.fancy span {
  display: inline-block;
  position: relative;
}

.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 0px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  top: 0;
  width: 600px;
}

.fancy span:before {
  right: 100%;
  margin-right: 50px;
}

.fancy span:after {
  left: 100%;
  margin-left: 50px;
}

.nftImage1 {
  width: 50%;
  max-width: 220px;
  height: auto;
  margin: 25px;
  border: solid 2px #6b601fad;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 5px #a89530;
}

.nftImage2 {
  width: 50%;
  max-width: 220px;
  margin: 25px;
  border: solid 2px #001050ed;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 5px #001877;
  ;
}